<template>
<dv-full-screen-container class="bg" style="width: 100%; height: 100%">
<p style="    color: #fff;
font-size: 0.15rem;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;">版本号：{{version}}</p>
  <van-row style="height:100%" justify="space-around">
    <van-col span="12" style="display: flex;">
      <div style="margin: auto;" class="box" @click="toLink(0)">
        <van-icon size="1.5rem" name="manager" />
        <p style="font-size:0.3rem">无芯片打包</p>
      </div>
    </van-col>
    <van-col span="12" style="display: flex;">
      <div style="margin: auto;" class="box" @click="toLink(1)">
        <van-icon size="1.5rem" name="send-gift" />
        <p style="font-size:0.3rem">有芯片打包</p>
      </div>
    </van-col>
  </van-row>
  </dv-full-screen-container>
</template>

<script>
// import Deskpack from "../../../components/factory_deskpack";
// import { ref } from 'vue';
// import api from '@/utils/api';
import store from "@/store/index";
import { Col, Row,Icon } from 'vant';
import { useRouter, useRoute } from 'vue-router'
export default {
  // name: "factory_deskpack",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const version = store.state.edition
    const toLink = (flag)=>{
      if(flag){
        console.log(route.query)
        router.push({
          path:'/factory/chippack',
          query: route.query
        })
      }else{
        console.log("return")
        router.push({
          path:'/factory/userpack',
          query: route.query
        })
      }
    } 
    return {
      version,
      toLink
    }
  }
};
</script>
<style lang="less" scoped>
p{
  margin: 0;
}
.bg {
  background-image: url("../../../assets/bj.jpg");
}
.box{
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.1rem;
}
</style>

